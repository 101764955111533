
import { myFirestore } from '../config/ConfigFirebase';
import { collection, query, where, getDocs, orderBy, doc, getDoc, onSnapshot, addDoc, updateDoc, arrayUnion } from "firebase/firestore";

import firebase from '../config/global-variables';
import FileSaver, { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import { connectStorageEmulator } from 'firebase/storage';
import JSZip from 'jszip';
//{ firebase.nameProyectFirebase, keyMessaging, firebaseNotificationSend }

//GET CONTACTS BY USER

const collectionUser = 'Users';

const getOperators = async (idCompany, uid, searchText, typeUser) => {
    let listUsers = [];

    try {
        if (searchText === "") {
            const response = query(collection(myFirestore, collectionUser), where('idCompany', '==', idCompany), orderBy('name'));
            const querySnapshot = await getDocs(response);

            //console.log(querySnapshot.size);
            querySnapshot.forEach((doc) => {
                let user = {
                    id: doc.id,
                    name: doc.data()['name'],
                    emial: doc.data()['email'],
                    lastSeen: doc.data()['lastSeen'],
                    serviceID: doc.data()['serviceID'],
                    status: doc.data()['status'],
                    token: doc.data()['token'],
                    image: doc.data()['image'],
                    userType: doc.data()['TypeUser'],
                    managerID: doc.data()['managerID'],
                    active: doc.data()['active'],
                }
                if (doc.id !== uid) {
                    let listManagerID = doc.data()['managerID'].split(',');
                    if (typeUser === 2) {

                        if (doc.data()['TypeUser'] === 4 && doc.data()['active'] === '1') {


                            listUsers.push(user);
                        }
                    }
                    if (typeUser === 3) {
                        if (doc.data()['TypeUser'] === 4 && doc.data()['active'] === '1') {

                            listManagerID.forEach(mid => {

                                if (mid === uid) {
                                    listUsers.push(user);
                                }

                            });

                        }

                    }

                }

            });
        }

        if (searchText !== "") {

            const newQuery = query(collection(myFirestore, collectionUser), where('idCompany', '==', idCompany), orderBy('name'));
            //query(collection(myFirestore,collectionUser),where('idCompany','==',idCompany),where('name','==',searchText));
            const querySnapshotsSR = await getDocs(newQuery);

            querySnapshotsSR.forEach((newdoc) => {
                const nameUser = newdoc.data()['name'];
                if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
                    let user = {
                        id: newdoc.id,
                        name: newdoc.data()['name'],
                        emial: newdoc.data()['email'],
                        lastSeen: newdoc.data()['lastSeen'],
                        serviceID: newdoc.data()['serviceID'],
                        status: newdoc.data()['status'],
                        token: newdoc.data()['token'],
                        image: newdoc.data()['image'],
                        userType: newdoc.data()['TypeUser'],
                        managerID: newdoc.data()['managerID'],
                        active: newdoc.data()['active'],
                    }
                    if (newdoc.id !== uid) {

                        let listManID = newdoc.data()['managerID'].split(',');

                        if (typeUser === 2) {

                            if (newdoc.data()['TypeUser'] === 4 && newdoc.data()['active'] === '1') {


                                listUsers.push(user);
                            }
                        }
                        if (typeUser === 3) {
                            if (newdoc.data()['TypeUser'] === 4 && newdoc.data()['active'] === '1') {

                                listManID.forEach(mid => {

                                    if (mid === uid) {
                                        listUsers.push(user);
                                    }

                                });
                                //listUsers.push(user);
                            }
                            // if (newdoc.data()['idCompany'] === idCompany && newdoc.data()['TypeUser'] === 2) {
                            //     listUsers.push(user);
                            // }
                        }
                        //listUsers.push(user);
                    }
                }

            })
        }
        console.log(`${typeUser}  - ${uid}   - ${listUsers} `)
        return listUsers;
    } catch (error) {
        Swal.fire({
            title: 'Problema de conexión.',
            text: 'No se puede obtener la lista de operadores.',
            icon: 'error',
        })
        return [];
    }

}

const generatePDF = async (initDate, endDate, listOperatorIDs, idCompany) => {
    try {
        Swal.fire({
            title: "Generando PDFs",
            allowOutsideClick: false,
            allowEscapeKey: false,
            html: "<b>Por favor espere...</b>",
            didOpen: () => {
                Swal.showLoading();
            },
        });

        // Cambio aplicado antes tenía https://bitacora.advanpro.com.mx/api/TravelLog
        const url = `https://bitacoraprd.advanpro.com.mx/api/TravelLog`;//FALTA AGRegar eL nombre de la funcion https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/TravelLog
        const optionsRequest = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                initDate: initDate,
                endDate: endDate,
                listOperators: listOperatorIDs,
                idCompany: idCompany
            }),
            // mode:'no-cors'
        };
        const response = await fetch(url, optionsRequest);
        console.log(response);
        Swal.close();
        if (response.status == 200) {
            const data = await response.json();
            const parseData = JSON.parse(data);
            const { Data } = parseData;
            if (Data.length > 0) {
                //create variables
                //here we create the folder zip
                const zip = new JSZip();
                const pdfZip = zip.folder('PDFs');
                Data.forEach((pdf, i) => {
                    // var blob = new Blob(pdf.blob, {type: "application/pdf"});
                    // FileSaver.saveAs(blob, `${pdf.title}`);
                    pdfZip.file(`${i}_${pdf.title}.pdf`, pdf.base64, { base64: true });
                });

                zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, `${initDate} a ${endDate}.zip`)
                });

            } else {
                Swal.fire({
                    title: 'Aviso.',
                    text: 'No se encontró información para generar PDFs con esas fechas, para los operadores seleccionados.',
                    icon: 'warning',
                })
            }
        } else {
            Swal.fire({
                title: 'Aviso.',
                text: 'No se puede generar el o los PDFs.',
                icon: 'warning',
            })
        }


    } catch (error) {
        Swal.fire({
            title: 'Problema de conexión al servidor.',
            text: 'No se puede generar el o los PDFs.',
            icon: 'error',
        })

    }
}

export { getOperators, generatePDF }